<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-button variant="primary" @click="addModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="items" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" @click="$router.push('/digital_signage/' + props.row.id)" class="mr-1">
                            <feather-icon
                                icon="EyeIcon"
                            />
                        </b-button>
                        <b-button variant="danger" @click="removeSignage(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label for="name">Name:</label>
                    <b-form-input
                        id="name"
                        type="text"
                        placeholder="Name"
                        v-model="addObject.name"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addSignage">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'

    export default {
        components: {
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BasicTable
        },
        data() {
            return {
                items: [],
                dataLoaded: false,

                addObject: {},
                addModalActive: false,

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/digital_signage')
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addSignage() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/digital_signage/', this.addObject)
                addPromise.then(function(response) {
                    thisIns.addModalActive = false
                    thisIns.$router.push(`/digital_signage/${  response.data.id}`)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            removeSignage(id) {
                this.dataLoaded = false
                const thisIns = this
                const deletePromise = this.$http.delete(`/api/management/v1/digital_signage/${  id}`)
                deletePromise.then(function() {
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>